import React from "react"
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"

export default function Chat() {
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot
  return (
    <>
      <link
        href="https://static.convaise.com/popup/latest/popup-v4.min.css?v=1.0.0"
        rel="stylesheet"
      ></link>
      <div
        id="convaise-wrapper"
        className="convaisecss-clear"
        data-name="Demo-Assistent"
        data-gdpr-mode="navbar"
        data-gdpr-url="https://convaise.com/datenschutz"
        data-persist-chat="true"
        data-show-name-button="true"
        data-news="false"
        data-botid="ddxur44gkzqviz2q"
        data-src="/convaise/demo-iframe/"
        data-avatar-url="https://static.convaise.com/avatars/avatarPerson.svg?v=1.0.0"
      />
      <Helmet>
        <script src={withPrefix("popup-v4.js")} type="text/javascript" />
      </Helmet>
    </>
  )
}
